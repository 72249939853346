<template>
  <ul class="pagination justify-content-center my-3">
    <li class="page-item" @click="handlePrev">
      <span class="page-link" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </span>
    </li>

    <li
      class="page-item"
      v-for="n in pageNumbers"
      :key="n"
      @click="(currentPage = n), $emit('click', n)"
    >
      <span class="page-link" :class="currentPage == n ? 'active bg-main' : ''">
        {{ n }}
      </span>
    </li>

    <li class="page-item" @click="handleNext">
      <span class="page-link" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ['click'],
  data() {
    return {
      start: 1,
      currentPage: 1,
      pageNumbers: [],
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.renderPages();
  },
  methods: {
    handlePrev() {
      if (this.start == 1) {
        return;
      }

      this.start -= this.pageCount;
      this.renderPages();
      this.$emit('click', this.currentPage);
    },
    handleNext() {
      if (this.pageNumbers.length < this.pageCount) {
        return;
      }

      this.start += this.pageCount;
      this.renderPages();
      this.$emit('click', this.currentPage);
    },
    renderPages() {
      const pageNumbers = [];

      for (
        let i = this.start;
        i < Math.min(this.start + this.pageCount, this.totalPage + 1);
        i++
      ) {
        pageNumbers.push(i);
      }

      this.currentPage = pageNumbers[0];
      this.pageNumbers = pageNumbers;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  all: unset;
}
.pagination {
  color: black;
}
.page-link {
  color: black;
  cursor: pointer;
}
.active {
  color: white;
  border-color: transparent;
}
</style>
